import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, ControlLabel, FormControl, FormGroup, Modal, Nav, NavItem } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    tabs: {
        marginBottom: "16px",
    },
    manualStoreNumbersInput: {
        minHeight: "80px",
        width: "100%",
    },
});

const strings = {
    modalTitle: "Upload Store Number List",
    uploadCsvTabLabel: "Upload CSV",
    manualTabLabel: "Manual Entry",
    uploadCsvStoreNumbersLabel: "Upload Store Numbers CSV File",
    manualPlaceholder: "Enter store numbers",
    manualStoreNumbersLabel: "Store Numbers",
    cancel: "Cancel",
    confirmButtonLabel: "Select Stores from List",
    invalidStoreNumbersParse:
        "The store numbers you entered could not be parsed. Please try again.",
    invalidStoreNumbersUnavailable:
        "The store numbers below were not found in the store list and could not be selected. Please try again.",
    ok: "Ok",
};

export default class UploadStoresModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        availableStoreNumbers: PropTypes.arrayOf(PropTypes.number),
        handleClose: PropTypes.func.isRequired,
        handleSelectStoreNumbers: PropTypes.func.isRequired,
    };

    state = {
        activeTab: 0,
        storeNumbersFile: undefined,
        manualStoreNumbers: "",
        showAlertModal: false,
        alertMsg: "",
        unavailableStoreNumbers: [],
    };

    _fileReader = new FileReader();

    componentDidMount() {
        this._fileReader.onload = () => {
            const storeNumbers = this._getCleanStoreNumbers(this._fileReader.result);
            this._selectStoreNumbers(storeNumbers);
        };
    }

    componentWillUnmount() {
        this._fileReader.abort();
    }

    _onSelectTab = key => {
        this.setState({ activeTab: key, ...(key === 1 && { storeNumbersFile: undefined }) });
    };

    _onStoreNumbersFileChange = e => {
        this.setState({
            storeNumbersFile: e.target.files[0],
        });
    };

    _onManualStoreNumbersChange = e => {
        this.setState({
            manualStoreNumbers: e.target.value,
        });
    };

    _getUnavailableStoreNumbers = storeNumbers => {
        const unavailableStoreNumbers = [];
        const availableStoreNumbersSet = this.props.availableStoreNumbers
            ? new Set(this.props.availableStoreNumbers)
            : null;
        if (storeNumbers && availableStoreNumbersSet) {
            storeNumbers.forEach(storeNumber => {
                if (!availableStoreNumbersSet.has(storeNumber)) {
                    unavailableStoreNumbers.push(storeNumber);
                }
            });
        }

        return unavailableStoreNumbers;
    };

    _getCleanStoreNumbers = storeNumbersStr => {
        let validStoreNumbers = true;

        const storeNumbers = storeNumbersStr
            .split(/,|\n/)
            .map(storeNumStr => storeNumStr.trim())
            .filter(storeNumStr => !!storeNumStr)
            .map(storeNumber => {
                const parsedStoreNumber = parseInt(storeNumber);
                if (isNaN(parsedStoreNumber)) {
                    validStoreNumbers = false;
                }
                return parsedStoreNumber;
            });

        if (!validStoreNumbers) {
            return undefined;
        }

        return new Set(storeNumbers);
    };

    _clearState = () => {
        this.setState({
            activeTab: 0,
            storeNumbersFile: undefined,
            manualStoreNumbers: "",
            showAlertModal: false,
            alertMsg: "",
            unavailableStoreNumbers: [],
        });
    };

    _selectStoreNumbers = storeNumbers => {
        if (!storeNumbers) {
            this.setState({
                showAlertModal: true,
                alertMsg: strings.invalidStoreNumbersParse,
            });
            return;
        }

        const unavailableStoreNumbers = this._getUnavailableStoreNumbers(storeNumbers);
        if (unavailableStoreNumbers.length > 0) {
            this.setState({
                showAlertModal: true,
                alertMsg: strings.invalidStoreNumbersUnavailable,
                unavailableStoreNumbers,
            });
            return;
        }

        this._clearState();
        this.props.handleSelectStoreNumbers(storeNumbers);
    };

    _handleCancel = () => {
        this._clearState();
        this.props.handleClose();
    };

    _handleClickConfirm = () => {
        let storeNumbers;
        switch (this.state.activeTab) {
            case 0:
                if (this.state.storeNumbersFile) {
                    this._fileReader.readAsText(this.state.storeNumbersFile);
                }
                return;
            case 1:
                storeNumbers = this._getCleanStoreNumbers(this.state.manualStoreNumbers);
                break;
            default:
                return;
        }

        this._selectStoreNumbers(storeNumbers);
    };

    _handleCloseAlertModal = () => {
        this.setState({
            showAlertModal: false,
            alertMsg: "",
            unavailableStoreNumbers: [],
        });
    };

    render() {
        const enableConfirm =
            (this.state.activeTab === 0 && !!this.state.storeNumbersFile) ||
            (this.state.activeTab === 1 && !!this.state.manualStoreNumbers);

        return (
            <>
                <Modal show={this.props.show} onHide={this._handleCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>{strings.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Nav
                            className={css(styles.tabs)}
                            bsStyle="tabs"
                            activeKey={this.state.activeTab}
                        >
                            <NavItem onSelect={this._onSelectTab} eventKey={0}>
                                {strings.uploadCsvTabLabel}
                            </NavItem>
                            <NavItem onSelect={this._onSelectTab} eventKey={1}>
                                {strings.manualTabLabel}
                            </NavItem>
                        </Nav>
                        {this.state.activeTab === 0 ? (
                            <div>
                                <FormGroup controlId="storeListUpload">
                                    <ControlLabel>
                                        {strings.uploadCsvStoreNumbersLabel}
                                    </ControlLabel>
                                    <FormControl
                                        type="file"
                                        accept=".csv"
                                        onChange={this._onStoreNumbersFileChange}
                                    />
                                </FormGroup>
                            </div>
                        ) : null}
                        {this.state.activeTab === 1 ? (
                            <FormGroup controlId="storeList">
                                <ControlLabel>{strings.manualStoreNumbersLabel}</ControlLabel>
                                <FormControl
                                    className={css(styles.manualStoreNumbersInput)}
                                    componentClass="textarea"
                                    value={this.state.manualStoreNumbers}
                                    placeholder={strings.manualPlaceholder}
                                    onChange={this._onManualStoreNumbersChange}
                                />
                            </FormGroup>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this._handleCancel}>{strings.cancel}</Button>
                        <Button
                            bsStyle="primary"
                            onClick={this._handleClickConfirm}
                            disabled={!enableConfirm}
                        >
                            {strings.confirmButtonLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showAlertModal} onHide={this._handleCloseAlertModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.alertMsg}</Modal.Title>
                    </Modal.Header>
                    {this.state.unavailableStoreNumbers.length > 0 ? (
                        <Modal.Body>{this.state.unavailableStoreNumbers.join(", ")}</Modal.Body>
                    ) : null}
                    <Modal.Footer>
                        <Button onClick={this._handleCloseAlertModal} bsStyle="primary">
                            {strings.ok}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
