export const orange = "#FB684C";
export const green = "#28A745";
export const lightBlue = "#E2EEFF";
export const blue = "#0066FF";

export const red = "#DC3545";
const redRgb = "220, 53, 69";
export const redRgb5 = `rgba(${redRgb}, 0.5)`;

export const black = "#000000";
export const lightGrey = "#EFEFEF";
export const darkGrey = "#808080";

export const white = "#FFFFFF";
const whiteRgb = "255, 255, 255";
export const whiteRgb3 = `rgba(${whiteRgb}, 0.3)`;
