/* eslint-disable react/jsx-no-bind */

import * as campaigns from "../../lib/api/campaigns";
import * as color from "../../styles/color";
import * as executionElementType from "../../enums/executionElementType";
import * as executionPlatform from "../../enums/executionPlatform";
import * as stores from "../../lib/api/stores";
import AlertModal from "../AlertModal";
import CampaignExecution from "../CampaignExecution";
import ConfirmationModal from "../ConfirmationModal";
import dateFns from "date-fns";
import DuplicateLandingPageModal from "../DuplicateLandingPageModal";
import executionElementDefaultName from "../../enums/executionElementDefaultName";
import FailedToLoadAlert from "../FailedToLoadAlert";
import PropTypes from "prop-types";
import logger from "../../lib/logger";
import LandingPageCrumbEditHeader from "../LandingPageCrumbEditHeader";
import Loader from "../Loader";
import LocationListSection from "../LocationListSection";
import React, { PureComponent } from "react";
import {
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    HelpBlock,
    Nav,
    NavItem,
    Row,
} from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    outerContainer: {
        position: "relative",
    },
    fullLandingPageUrl: {
        marginRight: "12px",
    },
    campaignInfo: {
        fontSize: "16px",
        fontWeight: 600,
    },
    navTabs: {
        marginBottom: "32px",
    },
    dateInput: {
        paddingTop: "0px",
    },
    errorText: {
        color: color.red,
    },
    loadingContainer: {
        position: "absolute",
        top: "50px",
        left: 0,
        paddingTop: "30vh",
        width: "100%",
        height: "100%",
        backgroundColor: color.whiteRgb3,
    },
});

const strings = {
    newCampaignSlug: "new",
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    campaigns: "Campaigns",
    saveCampaignButtonLabel: "Save Campaign",
    campaignInfo: "Campaign Info",
    addCampaign: "Add Campaign",
    edit: "Edit",
    campaign: "Campaign",
    campaignUrl: "/campaign",
    campaignsUrl: "/campaigns",
    available: "Available",
    saveSuccessMsg: "This campaign has been saved successfully!",
    loadErrorMsg: "There was an error retrieving data.",
    generalErrorMsg: "There was an error with your request.",
    name: "Name",
    namePlaceholder: "Enter name",
    activeCheckboxLabel: "Active",
    startDate: "Start Date",
    endDate: "End Date",
    confirmUpdateCampaign: "Update Campaign",
    tablLabelGeneralInfo: "General Info",
    tabLabelPos: "POS Media",
    tabLabelKiosk: "Kiosk Media",
    tabLabelMobile: "Mobile Media",
    tabLabelWeb: "Web Media",
    addExecutionElementsErrorMsg: "There was an error adding new execution elements.",
    replaceExecutionElementsErrorMsg: "There was an error replacing execution elements.",
    deleteExecutionElementsErrorMsg: "There was an error deleting execution elements.",
    updateExecutionElementsErrorMsgEnd: "You may have to redo (or undo) some changes.",
    fieldInUse: fieldName =>
        `This ${fieldName} is already in use. Select a different ${fieldName}.`,
    confirmMakeInactivePrompt: (defaultStoreCount, nonDefaultStoreCount) =>
        `This campaign is currently assigned to ${defaultStoreCount} location(s) as the default campaign and ${nonDefaultStoreCount} location(s) as the non-default campaign. Making this campaign inactive will remove this campaign from all of these locations. Are you sure you want to proceed?`,
    editDateDefaultCampaignAlertMsg: defaultStoreCount =>
        `This campaign is currently configured as the default campaign on ${defaultStoreCount} stores. Default campaigns cannot have a start or end date. Please remove this campaign as the default on all stores or remove the start/end dates to save your changes.`,
};

// TODO: Update backend to return these requirements and/or a required elements list
const elementRequirements = {
    [executionPlatform.pos]: {},
    [executionPlatform.kiosk]: {
        ["Home Screen"]: {
            image: {
                width: 1080,
                height: 1920,
            },
            video: {
                width: 1080,
                height: 1920,
            },
        },
    },
    [executionPlatform.mobile]: {},
    [executionPlatform.web]: {},
};

class CampaignPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    _handleAlertModalCloseGeneral = () => {
        this.setState({
            showAlertModal: false,
            alertMsg: "",
        });
    };

    _handleAlertModalCloseCreate = () => {
        this.props.history.push(strings.campaignsUrl);
    };

    _handleAlerModalCloseFunc = this._handleAlertModalCloseGeneral;

    state = {
        isLoading: true,
        showAlertModal: false,
        alertMsg: "",
        showConfirmModal: false,
        confirmPrompt: "",
        selectedTab: 1,
        campaign: null,
        name: "",
        startDate: "",
        endDate: "",
        isActive: true,
        stores: [],
        executions: [],
        inUseNames: new Set(),
        showDuplicateModal: false,
        duplicateName: "",
        defaultStoreCount: 0,
        nonDefaultStoreCount: 0,
    };

    _isNewCampaign = () => {
        return this.props.match.params.campaignId === strings.newCampaignSlug;
    };

    _compareExecution = (a, b) => a.name.localeCompare(b.name);

    _compareElement = (a, b) => a.data.name.localeCompare(b.data.name);

    _refineDate = dateStr => dateFns.format(dateFns.parse(dateStr), "YYYY-MM-DD");

    componentDidMount() {
        document.title = `${strings.loading} ${strings.titleSuffix}`;

        const locationState = this.props.location.state;
        const inUseNames = locationState ? locationState.inUseNames : new Set(); // If this field is missing, then the inUseNames state will be an empty set

        if (this._isNewCampaign()) {
            document.title = `${strings.addCampaign} ${strings.titleSuffix}`;

            const campaign = locationState ? locationState.campaign : undefined;
            if (campaign) {
                this.setState({
                    isLoading: false,
                    name: campaign.name ? campaign.name : "",
                    isActive: !!campaign.isActive,
                    startDate: campaign.startDate ? campaign.startDate : "",
                    endDate: campaign.endDate ? campaign.endDate : "",
                });
                return;
            }

            this.setState({
                isLoading: false,
                inUseNames,
            });
            return;
        }

        this.setState({ inUseNames });
        this._loadCampaignData();
        return;
    }

    _loadCampaignData = () => {
        campaigns
            .getCampaign(this.props.match.params.campaignId)
            .then(campaignResp => {
                document.title = `${strings.edit} ${campaignResp.name} ${strings.titleSuffix}`;

                const executions = {
                    [executionPlatform.pos]: [],
                    [executionPlatform.kiosk]: [],
                    [executionPlatform.mobile]: [],
                    [executionPlatform.web]: [],
                };
                for (const execution of campaignResp.executions) {
                    const platform = execution.platform;
                    switch (platform) {
                        case executionPlatform.pos:
                            executions[executionPlatform.pos].push({
                                ...execution,
                                elements: execution.elements.sort(this._compareElement),
                                newElements: [],
                                replaceElements: {},
                                deletedElements: {},
                                requirements:
                                    elementRequirements[executionPlatform.pos][execution.name],
                            });
                            break;
                        case executionPlatform.kiosk:
                            executions[executionPlatform.kiosk].push({
                                ...execution,
                                elements: execution.elements.sort(this._compareElement),
                                newElements: [],
                                replaceElements: {},
                                deletedElements: {},
                                requirements:
                                    elementRequirements[executionPlatform.kiosk][execution.name],
                            });
                            break;
                        case executionPlatform.mobile:
                            executions[executionPlatform.mobile].push({
                                ...execution,
                                elements: execution.elements.sort(this._compareElement),
                                newElements: [],
                                replaceElements: {},
                                deletedElements: {},
                                requirements:
                                    elementRequirements[executionPlatform.mobile][execution.name],
                            });
                            break;
                        case executionPlatform.web:
                            executions[executionPlatform.web].push({
                                ...execution,
                                elements: execution.elements.sort(this._compareElement),
                                newElements: [],
                                replaceElements: {},
                                deletedElements: {},
                                requirements:
                                    elementRequirements[executionPlatform.web][execution.name],
                            });
                            break;
                        default:
                            continue;
                    }
                }

                executions[executionPlatform.pos].sort(this._compareExecution);
                executions[executionPlatform.kiosk].sort(this._compareExecution);
                executions[executionPlatform.mobile].sort(this._compareExecution);
                executions[executionPlatform.web].sort(this._compareExecution);

                // TODO: Will need to update state store counts when unassigning from stores
                let defaultStoreCount = 0;
                let nonDefaultStoreCount = 0;
                campaignResp.stores.forEach(store => {
                    if (store.isDefaultCampaign) {
                        defaultStoreCount += 1;
                        return;
                    }
                    nonDefaultStoreCount += 1;
                });

                this.setState({
                    campaign: {
                        ...campaignResp,
                        startDate: campaignResp.startDate
                            ? this._refineDate(campaignResp.startDate)
                            : "",
                        endDate: campaignResp.endDate ? this._refineDate(campaignResp.endDate) : "",
                    },
                    name: campaignResp.name,
                    startDate: campaignResp.startDate
                        ? this._refineDate(campaignResp.startDate)
                        : "",
                    endDate: campaignResp.endDate ? this._refineDate(campaignResp.endDate) : "",
                    isActive: campaignResp.isActive,
                    defaultStoreCount,
                    nonDefaultStoreCount,
                    executions,
                    // Probably don't need to store stores in separate state field
                    stores: campaignResp.stores,
                });
            })
            .catch(err => {
                logger.warn(err);
                // Don't need to display AlertModal as rendering logic automatically shows FailedToLoadAlert in this case
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    _createCampaign = () => {
        this.setState({ isLoading: true });

        campaigns
            .createCampaign({
                name: this.state.name.trim(),
                isActive: this.state.isActive,
                ...(this.state.startDate && { startDate: this.state.startDate }),
                ...(this.state.endDate && { endDate: this.state.endDate }),
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    showAlertModal: true,
                    alertMsg: strings.saveSuccessMsg,
                });
                this._handleAlerModalCloseFunc = this._handleAlertModalCloseCreate;
            })
            .catch(err => {
                logger.warn(err);
                let errorMsg = strings.generalErrorMsg;
                if (
                    err &&
                    err.response &&
                    err.response.status === 400 &&
                    err.response.data &&
                    typeof err.response.data === "string"
                ) {
                    errorMsg = err.response.data;
                }
                this.setState({
                    isLoading: false,
                    showAlertModal: true,
                    alertMsg: errorMsg,
                });
            });
    };

    _getExecutionUpdatesForPlatform = platform => {
        const newElements = [];
        const replaceElements = {};
        let deletedElements = {};

        const platformExecutions = this.state.executions[platform];
        if (!platformExecutions) {
            return { newElements, replaceElements, deletedElements };
        }

        platformExecutions.forEach(execution => {
            execution.newElements.forEach(newElement => {
                switch (newElement.type) {
                    case executionElementType.image:
                        if (newElement.data.image) {
                            newElements.push({
                                ...newElement,
                                executionId: execution.id,
                                data: {
                                    ...newElement.data,
                                    name: newElement.data.name
                                        ? newElement.data.name.trim()
                                        : executionElementDefaultName[executionElementType.image],
                                },
                            });
                        }
                        break;
                    case executionElementType.video:
                        if (newElement.data.video) {
                            newElements.push({
                                ...newElement,
                                executionId: execution.id,
                                data: {
                                    ...newElement.data,
                                    name: newElement.data.name
                                        ? newElement.data.name.trim()
                                        : executionElementDefaultName[executionElementType.video],
                                },
                            });
                        }
                        break;
                    default:
                }
            });
            Object.keys(execution.replaceElements).forEach(replaceElementId => {
                const replaceElement = execution.replaceElements[replaceElementId];
                switch (replaceElement.type) {
                    case executionElementType.image:
                        if (replaceElement.data.image) {
                            replaceElements[replaceElement.id] = {
                                ...replaceElement,
                                executionId: execution.id,
                            };
                        }
                        break;
                    case executionElementType.video:
                        if (replaceElement.data.video) {
                            replaceElements[replaceElement.id] = {
                                ...replaceElement,
                                executionId: execution.id,
                            };
                        }
                        break;
                    default:
                }
            });
            deletedElements = { ...deletedElements, ...execution.deletedElements };
        });

        return { newElements, replaceElements, deletedElements };
    };

    _getExecutionUpdates = () => {
        const posExecutionUpdates = this._getExecutionUpdatesForPlatform(executionPlatform.pos);
        const kioskExecutionUpdates = this._getExecutionUpdatesForPlatform(executionPlatform.kiosk);
        const mobileExecutionUpdates = this._getExecutionUpdatesForPlatform(
            executionPlatform.mobile,
        );
        const webExecutionUpdates = this._getExecutionUpdatesForPlatform(executionPlatform.web);

        const newElements = [
            ...posExecutionUpdates.newElements,
            ...kioskExecutionUpdates.newElements,
            ...mobileExecutionUpdates.newElements,
            ...webExecutionUpdates.newElements,
        ];
        const replaceElements = {
            ...posExecutionUpdates.replaceElements,
            ...kioskExecutionUpdates.replaceElements,
            ...mobileExecutionUpdates.replaceElements,
            ...webExecutionUpdates.replaceElements,
        };
        const deletedElements = {
            ...posExecutionUpdates.deletedElements,
            ...kioskExecutionUpdates.deletedElements,
            ...mobileExecutionUpdates.deletedElements,
            ...webExecutionUpdates.deletedElements,
        };

        return {
            newElements,
            replaceElements,
            deletedElements,
        };
    };

    _getAddElementCall = element => {
        switch (element.type) {
            case executionElementType.image:
                return campaigns.addImageExecutionElement(element.executionId, element.data.name, {
                    image: element.data.image,
                    centerX: element.data.crop.x,
                    centerY: element.data.crop.y,
                    width: element.data.crop.width,
                    height: element.data.crop.height,
                    imageData: element.data.imageData,
                });
            case executionElementType.video:
                return campaigns.addVideoExecutionElement(
                    element.executionId,
                    element.data.name,
                    element.data.video,
                );
            default:
        }
    };

    _getExplicitAddExecutionElementCalls = executionUpdates => {
        const calls = [];

        executionUpdates.newElements.forEach(element =>
            calls.push(this._getAddElementCall(element)),
        );

        return calls;
    };

    _getExplicitDeleteExecutionElementCalls = executionUpdates => {
        const calls = [];

        Object.keys(executionUpdates.deletedElements).forEach(elementId => {
            const element = executionUpdates.deletedElements[elementId];
            calls.push(campaigns.deleteExecutionElement(element.type, element.id));
        });

        return calls;
    };

    _getReplaceAddExecutionElementCalls = executionUpdates => {
        const calls = [];

        // Sorting keys list for consistent indexing/referencing in _editCampaign on failures
        Object.keys(executionUpdates.replaceElements)
            .sort()
            .forEach(elementId => {
                const element = executionUpdates.replaceElements[elementId];
                calls.push(this._getAddElementCall(element));
            });

        return calls;
    };

    _getReplaceDeleteExecutionElementCalls = (executionUpdates, excludeElementIds) => {
        const calls = [];

        Object.keys(executionUpdates.replaceElements).forEach(elementId => {
            if (!excludeElementIds.has(elementId)) {
                const element = executionUpdates.replaceElements[elementId];
                calls.push(campaigns.deleteExecutionElement(element.type, element.id));
            }
        });

        return calls;
    };

    _editCampaign = async () => {
        this.setState({ isLoading: true });

        const editedCampaign = {
            ...(this.state.campaign.name !== this.state.name && {
                name: this.state.name.trim(),
            }),
            ...(this.state.campaign.isActive !== this.state.isActive && {
                isActive: this.state.isActive,
            }),
            ...(this.state.campaign.startDate !== this.state.startDate && {
                startDate: this.state.startDate,
            }),
            ...(this.state.campaign.endDate !== this.state.endDate && {
                endDate: this.state.endDate,
            }),
        };

        let newInUseNames;
        if (editedCampaign.name) {
            newInUseNames = new Set(this.state.inUseNames);
            newInUseNames.delete(this.state.campaign.name);
            newInUseNames.add(editedCampaign.name);
        }

        const generalEditSuccess = await campaigns
            .editCampaign(this.state.campaign.id, editedCampaign)
            .then(() => {
                return true;
            })
            .catch(err => {
                logger.warn(err);
                let errorMsg = strings.generalErrorMsg;
                if (
                    err &&
                    err.response &&
                    err.response.status === 400 &&
                    err.response.data &&
                    typeof err.response.data === "string"
                ) {
                    errorMsg = err.response.data;
                }
                this.setState({
                    isLoading: false,
                    showAlertModal: true,
                    alertMsg: errorMsg,
                });

                return false;
            });

        if (!generalEditSuccess) {
            return;
        }

        const executionUpdates = this._getExecutionUpdates();

        const allElementsExplicitlyAdded = await Promise.allSettled(
            this._getExplicitAddExecutionElementCalls(executionUpdates),
        ).then(respList => {
            return !respList.some(resp => resp.status === "rejected");
        });

        const allElementsExplicitlyDeleted = await Promise.allSettled(
            this._getExplicitDeleteExecutionElementCalls(executionUpdates),
        ).then(respList => {
            return !respList.some(resp => resp.status === "rejected");
        });

        const failedReplaceElementIds = await Promise.allSettled(
            this._getReplaceAddExecutionElementCalls(executionUpdates),
        ).then(respList => {
            // Sorting keys to match _getReplaceAddExecutionElementCalls
            const replaceElementIds = Object.keys(executionUpdates.replaceElements).sort();
            const failedIds = new Set();
            respList.forEach((resp, i) => {
                if (resp.status === "rejected") {
                    const elementId = replaceElementIds[i];
                    if (elementId) {
                        failedIds.add(elementId);
                    }
                }
            });
            return failedIds;
        });

        // Will attempt to delete old elements if their replacements were successfully added
        const allSuccessfulReplaceElementsDeleted = await Promise.allSettled(
            this._getReplaceDeleteExecutionElementCalls(executionUpdates, failedReplaceElementIds),
        ).then(respList => {
            return !respList.some(resp => resp.status === "rejected");
        });

        if (
            !allElementsExplicitlyAdded ||
            !allElementsExplicitlyDeleted ||
            failedReplaceElementIds.size > 0 ||
            !allSuccessfulReplaceElementsDeleted
        ) {
            const alertMsg = `${
                !allElementsExplicitlyAdded ? `${strings.addExecutionElementsErrorMsg}` : ""
            } ${
                !allElementsExplicitlyDeleted ? `${strings.deleteExecutionElementsErrorMsg}` : ""
            } ${
                failedReplaceElementIds.size > 0 || !allSuccessfulReplaceElementsDeleted
                    ? `${strings.replaceExecutionElementsErrorMsg}`
                    : ""
            } ${strings.updateExecutionElementsErrorMsgEnd}`;

            this.setState({
                showAlertModal: true,
                alertMsg,
            });
        } else {
            this.setState({
                showAlertModal: true,
                alertMsg: strings.saveSuccessMsg,
            });
        }

        if (newInUseNames && generalEditSuccess) {
            this.setState({ inUseNames: newInUseNames });
        }

        this._loadCampaignData();
    };

    _handleSavePage = () => {
        if (this.state.campaign && !this._isNewCampaign()) {
            // If deactivating campaign, check if any stores assigned
            if (
                (this.state.defaultStoreCount > 0 || this.state.nonDefaultStoreCount > 0) &&
                this.state.campaign.isActive &&
                !this.state.isActive
            ) {
                this.setState({
                    showConfirmModal: true,
                    confirmPrompt: strings.confirmMakeInactivePrompt(
                        this.state.defaultStoreCount,
                        this.state.nonDefaultStoreCount,
                    ),
                });
                return;
            }

            // If adding a start/end date, check if any default stores assigned. if yes, prevent editing
            if (
                this.state.defaultStoreCount > 0 &&
                !this.state.campaign.startDate &&
                !this.state.campaign.endDate &&
                (this.state.startDate || this.state.endDate)
            ) {
                this.setState({
                    showAlertModal: true,
                    alertMsg: strings.editDateDefaultCampaignAlertMsg(this.state.defaultStoreCount),
                });
                return;
            }

            this._editCampaign();
            return;
        }

        this._createCampaign();
    };

    _showDuplicateModal = () => this.setState({ showDuplicateModal: true });

    _handleCloseDuplicateModal = () =>
        this.setState({ showDuplicateModal: false, duplicateName: "" });

    _handleDuplicate = () => {
        // TODO: Will need to duplicate other, non-general information as well
        this.props.history.push(`${strings.campaignUrl}/${strings.newCampaignSlug}`, {
            campaign: {
                name: this.state.duplicateName.trim(),
                isActive: this.state.isActive,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            },
            inUseNames: this.state.inUseNames,
        });
    };

    _getNameInUse = name =>
        this.state.inUseNames.has(name) &&
        (!this.state.campaign || (!!this.state.campaign && this.state.campaign.name !== name));
    _getIsValidName = value => !!value && !this._getNameInUse(value);

    _getDuplicateNameInUse = name => this.state.inUseNames.has(name);
    _getIsValidDuplicateName = value => !!value && !this._getDuplicateNameInUse(value);

    _getAvailabilityMsg = (value, fieldName, inUseFunc, isValidFunc) => {
        if (inUseFunc(value)) {
            return strings.fieldInUse(fieldName);
        }

        return isValidFunc(value) ? `${fieldName} ${strings.available}` : "";
    };

    _handleConfirmModalCancel = () => this.setState({ showConfirmModal: false, confirmPrompt: "" });

    _handleConfirmModalConfirm = () => {
        this.setState({ showConfirmModal: false, confirmPrompt: "" });
        this._editCampaign();
    };

    _onSelect = v => {
        switch (v) {
            case 1:
                this.setState({ selectedTab: 1 });
                return;
            case 2:
                this.setState({ selectedTab: 2 });
                return;
            case 3:
                this.setState({ selectedTab: 3 });
                return;
            case 4:
                this.setState({ selectedTab: 4 });
                return;
            case 5:
                this.setState({ selectedTab: 5 });
                return;
            default:
        }
    };

    _updatePlatformExecutions = (platform, executionId, getNewExecution) => {
        this.setState(prevState => {
            const platformExecutions = prevState.executions[platform];
            const executionIndex = platformExecutions
                ? platformExecutions.findIndex(e => e.id === executionId)
                : -1;

            if (executionIndex === -1) {
                return;
            }

            const execution = platformExecutions[executionIndex];
            const newExecution = getNewExecution(execution);

            if (!newExecution) {
                return;
            }

            const newPlatformExecutions = [
                ...platformExecutions.slice(0, executionIndex),
                newExecution,
                ...platformExecutions.slice(executionIndex + 1, platformExecutions.length),
            ];

            return {
                executions: {
                    ...prevState.executions,
                    [platform]: newPlatformExecutions,
                },
            };
        });
    };

    _addNewElement = (platform, executionId, element) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            return { ...execution, newElements: [...execution.newElements, element] };
        });
    };

    _editNewElement = (platform, executionId, i, editedElement) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const newElements = [...execution.newElements];
            newElements[i] = editedElement;
            return { ...execution, newElements };
        });
    };

    _removeNewElement = (platform, executionId, i) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const newElements = [...execution.newElements];
            newElements.splice(i, 1);
            return { ...execution, newElements };
        });
    };

    _addReplaceElement = (platform, executionId, newElement) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            return {
                ...execution,
                replaceElements: { ...execution.replaceElements, [newElement.id]: newElement },
            };
        });
    };

    _editReplaceElement = (platform, executionId, editedElement) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const replaceElements = { ...execution.replaceElements };
            replaceElements[editedElement.id] = editedElement;
            return { ...execution, replaceElements };
        });
    };

    _removeReplaceElement = (platform, executionId, elementId) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const replaceElements = { ...execution.replaceElements };
            delete replaceElements[elementId];
            return { ...execution, replaceElements };
        });
    };

    _addDeletedElement = (platform, executionId, element) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const deletedElements = { ...execution.deletedElements, [element.id]: element };
            return {
                ...execution,
                deletedElements,
            };
        });
    };

    _removeDeletedElement = (platform, executionId, elementId) => {
        this._updatePlatformExecutions(platform, executionId, execution => {
            const deletedElements = { ...execution.deletedElements };
            delete deletedElements[elementId];
            return {
                ...execution,
                deletedElements,
            };
        });
    };
    _unassignStore = (storeId, isDefault) => {
        this.setState({ isLoading: true });
        stores
            .unassignCampaign(storeId, isDefault)
            .then(() => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        stores: prevState.stores.filter(s => s.id !== storeId),
                        defaultStoreCount: isDefault
                            ? prevState.defaultStoreCount - 1
                            : prevState.defaultStoreCount,
                        nonDefaultStoreCount: !isDefault
                            ? prevState.nonDefaultStoreCount - 1
                            : prevState.nonDefaultStoreCount,
                    };
                });
            })
            .catch(err => {
                logger.warn(err);
                let errorMsg = strings.generalErrorMsg;
                if (
                    err &&
                    err.response &&
                    err.response.status === 400 &&
                    err.response.data &&
                    typeof err.response.data === "string"
                ) {
                    errorMsg = err.response.data;
                }
                this.setState({
                    showAlertModal: true,
                    alertMsg: errorMsg,
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    render() {
        if (!this.state.campaign && !this._isNewCampaign()) {
            if (this.state.isLoading) {
                return (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                );
            }

            return <FailedToLoadAlert type={strings.campaign} />;
        }

        const campaign = this.state.campaign;

        const isInputValid = this._getIsValidName(this.state.name.trim());
        const executionUpdates = this._getExecutionUpdates();

        const isChanged =
            !!campaign &&
            (campaign.name !== this.state.name.trim() ||
                campaign.isActive !== this.state.isActive ||
                campaign.startDate !== this.state.startDate ||
                campaign.endDate !== this.state.endDate ||
                executionUpdates.newElements.length > 0 ||
                Object.keys(executionUpdates.replaceElements).length > 0 ||
                Object.keys(executionUpdates.deletedElements).length > 0);

        const isDuplicateModalEnabled = !this.state.isLoading && !isChanged;

        const isSavePageEnabled =
            !this.state.isLoading && isInputValid && (isChanged || this._isNewCampaign());

        const isDuplicateEnabled = this._getIsValidDuplicateName(this.state.duplicateName.trim());

        return (
            <div className={css(styles.outerContainer)}>
                <Grid>
                    <LandingPageCrumbEditHeader
                        landingPagesLinkText={strings.campaigns}
                        landingPagesLinkUrl={strings.campaignsUrl}
                        pageTitle={
                            this._isNewCampaign()
                                ? strings.addCampaign
                                : `${strings.edit} ${campaign.name}`
                        }
                        isDuplicateEnabled={isDuplicateModalEnabled}
                        onDuplicate={this._showDuplicateModal}
                        isDuplicateHidden={this._isNewCampaign()}
                        isSavePageEnabled={isSavePageEnabled}
                        savePageButtonLabel={strings.saveCampaignButtonLabel}
                        onSavePage={this._handleSavePage}
                    />
                    <Row>
                        <Col md={12}>
                            <Nav
                                bsStyle="tabs"
                                activeKey={this.state.selectedTab}
                                className={css(styles.navTabs)}
                                disabled={this.state.isLoading}
                            >
                                <NavItem onSelect={this._onSelect} eventKey={1} id="nav-tabs">
                                    {strings.tablLabelGeneralInfo}
                                </NavItem>
                                <NavItem
                                    onSelect={this._onSelect}
                                    eventKey={2}
                                    id="nav-tabs"
                                    disabled={this.state.isLoading || this._isNewCampaign()}
                                >
                                    {strings.tabLabelPos}
                                </NavItem>
                                <NavItem
                                    onSelect={this._onSelect}
                                    eventKey={3}
                                    id="nav-tabs"
                                    disabled={this.state.isLoading || this._isNewCampaign()}
                                >
                                    {strings.tabLabelKiosk}
                                </NavItem>
                                <NavItem
                                    onSelect={this._onSelect}
                                    eventKey={4}
                                    id="nav-tabs"
                                    disabled={this.state.isLoading || this._isNewCampaign()}
                                >
                                    {strings.tabLabelMobile}
                                </NavItem>
                                <NavItem
                                    onSelect={this._onSelect}
                                    eventKey={5}
                                    id="nav-tabs"
                                    disabled={this.state.isLoading || this._isNewCampaign()}
                                >
                                    {strings.tabLabelWeb}
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    {this.state.selectedTab === 1 ? (
                        <Row>
                            <Col md={5}>
                                <form>
                                    <div className={css(styles.campaignInfo)}>
                                        {strings.campaignInfo}
                                    </div>
                                    <FormGroup controlId="name">
                                        <ControlLabel>{strings.name}</ControlLabel>
                                        <FormControl
                                            required
                                            type="text"
                                            value={this.state.name}
                                            placeholder={strings.namePlaceholder}
                                            onChange={e => this.setState({ name: e.target.value })}
                                            disabled={this.state.isLoading}
                                        />
                                        <HelpBlock
                                            className={css(
                                                this._getNameInUse(this.state.name.trim()) &&
                                                    styles.errorText,
                                            )}
                                        >
                                            {this._getAvailabilityMsg(
                                                this.state.name.trim(),
                                                strings.name,
                                                this._getNameInUse,
                                                this._getIsValidName,
                                            )}
                                        </HelpBlock>
                                    </FormGroup>
                                    <FormGroup controlId="active">
                                        <Checkbox
                                            checked={this.state.isActive}
                                            onChange={() =>
                                                this.setState({
                                                    isActive: !this.state.isActive,
                                                })
                                            }
                                            disabled={this.state.isLoading}
                                        >
                                            {strings.activeCheckboxLabel}
                                        </Checkbox>
                                    </FormGroup>
                                    <FormGroup controlId="startDate">
                                        <ControlLabel>{strings.startDate}</ControlLabel>
                                        <FormControl
                                            required
                                            type="date"
                                            value={this.state.startDate}
                                            onChange={e =>
                                                this.setState({ startDate: e.target.value })
                                            }
                                            className={css(styles.dateInput)}
                                            disabled={this.state.isLoading}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="endDate">
                                        <ControlLabel>{strings.endDate}</ControlLabel>
                                        <FormControl
                                            required
                                            type="date"
                                            value={this.state.endDate}
                                            onChange={e =>
                                                this.setState({ endDate: e.target.value })
                                            }
                                            className={css(styles.dateInput)}
                                            disabled={this.state.isLoading}
                                        />
                                    </FormGroup>
                                </form>
                            </Col>
                            <Col md={6} mdPush={1}>
                                <LocationListSection
                                    stores={this.state.stores}
                                    onUnassignStore={this._unassignStore}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    {this.state.selectedTab === 3 ? (
                        <Row>
                            <Col md={12}>
                                {this.state.executions[executionPlatform.kiosk].map(execution => (
                                    <CampaignExecution
                                        key={execution.id}
                                        platform={executionPlatform.kiosk}
                                        execution={execution}
                                        addNewElement={this._addNewElement}
                                        editNewElement={this._editNewElement}
                                        removeNewElement={this._removeNewElement}
                                        addReplaceElement={this._addReplaceElement}
                                        editReplaceElement={this._editReplaceElement}
                                        removeReplaceElement={this._removeReplaceElement}
                                        addDeletedElement={this._addDeletedElement}
                                        removeDeletedElement={this._removeDeletedElement}
                                        disabled={this.state.isLoading}
                                    />
                                ))}
                            </Col>
                        </Row>
                    ) : null}
                </Grid>
                <DuplicateLandingPageModal
                    show={this.state.showDuplicateModal}
                    itemName={strings.campaign}
                    handleClose={this._handleCloseDuplicateModal}
                    handleDuplicate={this._handleDuplicate}
                    duplicateDisabled={!isDuplicateEnabled}
                >
                    <FormGroup controlId="duplicate-name">
                        <ControlLabel>{strings.name}</ControlLabel>
                        <FormControl
                            type="text"
                            required
                            value={this.state.duplicateName}
                            placeholder={strings.namePlaceholder}
                            onChange={e => this.setState({ duplicateName: e.target.value })}
                        />
                        <HelpBlock
                            className={css(
                                this._getDuplicateNameInUse(this.state.duplicateName.trim()) &&
                                    styles.errorText,
                            )}
                        >
                            {this._getAvailabilityMsg(
                                this.state.duplicateName.trim(),
                                strings.name,
                                this._getDuplicateNameInUse,
                                this._getIsValidDuplicateName,
                            )}
                        </HelpBlock>
                    </FormGroup>
                </DuplicateLandingPageModal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.alertMsg}
                    handleClose={this._handleAlerModalCloseFunc}
                />
                <ConfirmationModal
                    show={this.state.showConfirmModal}
                    prompt={this.state.confirmPrompt}
                    confirmButtonLabel={strings.confirmUpdateCampaign}
                    handleCancel={this._handleConfirmModalCancel}
                    handleConfirm={this._handleConfirmModalConfirm}
                />
                {this.state.isLoading ? (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default CampaignPage;
