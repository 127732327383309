/* eslint-disable react/jsx-no-bind */

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Row } from "react-bootstrap";

const styles = StyleSheet.create({
    checkboxContainer: {
        marginTop: "24px",
    },
    addPageButton: {
        width: "100%",
        marginTop: "16px",
        marginBottom: "15px",
    },
});

const strings = {
    showInactiveLabel: "Show Inactive",
    filterLabel: "Filter:",
    enterText: "Enter text",
    addPage: "Add Page",
};

const HEADER_ITEM_COL_WIDTHS = {
    checkboxWidth: 2,
    filterInputWidth: 3,
    addPageButtonWidth: 2,
};

class LandingPageHeader extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        showInactivePages: PropTypes.bool,
        onChangeShowInactivePages: PropTypes.func,
        filterLabel: PropTypes.string,
        filter: PropTypes.string,
        onChangeFilter: PropTypes.func,
        onAddPage: PropTypes.func,
        buttonLabel: PropTypes.string,
    };

    render() {
        const showShowInactivePagesCheckbox = this.props.showInactivePages !== undefined;
        const showFilterInput = this.props.filter !== undefined && !!this.props.onChangeFilter;
        const showAddPageButon = !!this.props.onAddPage;

        let addPageButtonPush = 5;
        if (!showShowInactivePagesCheckbox) {
            addPageButtonPush += HEADER_ITEM_COL_WIDTHS.checkboxWidth;
        }
        if (!showFilterInput) {
            addPageButtonPush += HEADER_ITEM_COL_WIDTHS.filterInputWidth;
        }

        return (
            <>
                <Row>
                    <Col md={12}>
                        <h3>{this.props.title}</h3>
                    </Col>
                </Row>
                <Row>
                    {showShowInactivePagesCheckbox ? (
                        <Col md={HEADER_ITEM_COL_WIDTHS.checkboxWidth}>
                            <FormGroup className={css(styles.checkboxContainer)}>
                                <Checkbox
                                    checked={this.props.showInactivePages}
                                    onChange={this.props.onChangeShowInactivePages}
                                >
                                    {strings.showInactiveLabel}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    ) : null}
                    {showFilterInput ? (
                        <Col md={HEADER_ITEM_COL_WIDTHS.filterInputWidth}>
                            <FormGroup controlId="filter">
                                <ControlLabel>
                                    {this.props.filterLabel
                                        ? this.props.filterLabel
                                        : strings.filterLabel}
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.props.filter}
                                    placeholder={strings.enterText}
                                    onChange={this.props.onChangeFilter}
                                />
                            </FormGroup>
                        </Col>
                    ) : null}
                    {showAddPageButon ? (
                        <Col
                            md={HEADER_ITEM_COL_WIDTHS.addPageButtonWidth}
                            mdPush={addPageButtonPush}
                        >
                            <Button
                                bsStyle="primary"
                                className={css(styles.addPageButton)}
                                onClick={this.props.onAddPage}
                            >
                                {this.props.buttonLabel ? this.props.buttonLabel : strings.addPage}
                            </Button>
                        </Col>
                    ) : null}
                </Row>
            </>
        );
    }
}

export default LandingPageHeader;
