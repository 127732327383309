import * as color from "../styles/color";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    badgeContainer: {
        width: "fit-content",
        borderRadius: "4px",
        height: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: color.white,
        fontSize: "10px",
        fontWeight: 500,
    },
});

export default class CampaignBadge extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.string.isRequired,
        color: PropTypes.string,
    };

    render() {
        return (
            <div
                className={css(styles.badgeContainer, this.props.className)}
                style={{ backgroundColor: this.props.color ? this.props.color : color.darkGrey }}
            >
                {this.props.children}
            </div>
        );
    }
}
