import * as color from "../styles/color";
import * as executionElementType from "../enums/executionElementType";
import executionElementDefaultName from "../enums/executionElementDefaultName";
import ImageSetUploader from "./ImageSetUploader";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import VideoUploader from "./VideoUploader";
import XIcon from "./assets/x-icon.svg";
import { Button, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    elementContainer: {
        position: "relative",
        width: "calc((100% - 32px) / 3)",
        minWidth: "calc((100% - 32px) / 3)",
        maxWidth: "calc((100% - 32px) / 3)",
        overflow: "hidden",
        border: "1px solid",
        borderColor: color.lightGrey,
        borderRadius: "4px",
        padding: "24px",
    },
    imageNameInputContainer: {
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    removeNewElementButton: {
        position: "absolute",
        top: "2px",
        right: "2px",
        backgroundColor: "initial",
        border: "initial",
        boxShadow: "initial",
        marginBottom: "8px",
        zIndex: 1,
        ":active": {
            opacity: "60%",
        },
    },
    imageElementContainer: {
        padding: "4px",
        paddingTop: "24px",
    },
    imageUploader: {
        marginTop: 0,
    },
});

const strings = {
    removeNewElementAccessibility: "Remove new element",
    nameInputLabel: "Name (cannot edit later)",
};

class CampaignExecutionNewElement extends PureComponent {
    static propTypes = {
        className: PropTypes.object,
        index: PropTypes.number.isRequired,
        element: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string.isRequired,
            data: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }),
        requirements: PropTypes.shape({
            image: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            video: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        disabled: PropTypes.bool,
        hideNameInput: PropTypes.bool,
        onUpdateData: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    _handleImageFileUpdate = (image, crop, imageData) => {
        this.props.onUpdateData(this.props.index, {
            ...this.props.element,
            data: {
                ...this.props.element.data,
                image,
                crop,
                imageData,
            },
        });
    };

    _handleVideoFileUpdate = video => {
        this.props.onUpdateData(this.props.index, {
            ...this.props.element,
            data: {
                ...this.props.element.data,
                video,
            },
        });
    };

    _handleChangeName = e => {
        this.props.onUpdateData(this.props.index, {
            ...this.props.element,
            data: {
                ...this.props.element.data,
                name: e.target.value,
            },
        });
    };

    _handleRemove = () => this.props.onRemove(this.props.index);

    render() {
        return (
            <div
                className={css(
                    styles.elementContainer,
                    this.props.element.type === executionElementType.image &&
                        styles.imageElementContainer,
                    this.props.className,
                )}
            >
                <Button
                    className={css(styles.removeNewElementButton)}
                    onClick={this._handleRemove}
                    disabled={this.props.disabled}
                >
                    <img alt={strings.removeNewElementAccessibility} src={XIcon} />
                </Button>
                {!this.props.hideNameInput ? (
                    <FormGroup
                        controlId="name"
                        className={css(
                            this.props.element.type === executionElementType.image &&
                                styles.imageNameInputContainer,
                        )}
                    >
                        <ControlLabel>{strings.nameInputLabel}</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.props.element.data.name}
                            placeholder={executionElementDefaultName[this.props.element.type]}
                            onChange={this._handleChangeName}
                            disabled={this.props.disabled}
                        />
                    </FormGroup>
                ) : null}
                {this.props.element.type === executionElementType.image ? (
                    <ImageSetUploader
                        className={styles.imageUploader}
                        hideLabel
                        minWidth={
                            this.props.requirements && this.props.requirements.image
                                ? this.props.requirements.image.width
                                : undefined
                        }
                        minHeight={
                            this.props.requirements && this.props.requirements.image
                                ? this.props.requirements.image.height
                                : undefined
                        }
                        // Don't want to implement full typing with prop-types, especially if we plan to switch to TypeScript
                        // eslint-disable-next-line react/prop-types
                        previewImage={this.props.element.data.imageData}
                        onSubmit={this._handleImageFileUpdate}
                    />
                ) : null}
                {this.props.element.type === executionElementType.video ? (
                    <VideoUploader
                        hideTitle
                        requirements={
                            this.props.requirements ? this.props.requirements.video : undefined
                        }
                        onFileUpdate={this._handleVideoFileUpdate}
                    />
                ) : null}
            </div>
        );
    }
}

export default CampaignExecutionNewElement;
