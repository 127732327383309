import instance from "./instance";
const responseData = response => response.data;

export function getCampaigns() {
    return instance
        .request({ url: "/cms/marketing/campaigns", method: "GET"})
        .then(responseData);
}

export function getCampaign(campaignId) {
    return instance
        .request({ url: `/cms/marketing/campaign/${campaignId}`, method: "GET" })
        .then(responseData);
}

export function getExecutions() {
    return instance.request({ url: `/cms/marketing/executions`, method: "GET" }).then(responseData);
}

export function createCampaign(campaign) {
    return instance
        .request({ url: "/cms/marketing/campaign", method: "POST", data: campaign })
        .then(responseData);
}

export function editCampaign(campaignId, campaign) {
    return instance.request({
        url: `/cms/marketing/campaign/${campaignId}`,
        method: "PUT",
        data: campaign,
    });
}

export function assignCampaign(campaignId, isDefault, storeIds) {
    return instance.request({
        url: `/cms/marketing/campaign/${campaignId}/assign`,
        method: "POST",
        data: {
            isDefault,
            storeIds,
        },
    });
}

export function addImageExecutionElement(campaignExecutionId, name, imageData) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image.image", imageData.image);
    formData.append("image[centerX]", imageData.centerX);
    formData.append("image[centerY]", imageData.centerY);
    formData.append("image[width]", imageData.width);
    formData.append("image[height]", imageData.height);

    return instance.request({
        url: `/cms/marketing/campaign/campaign-execution/${campaignExecutionId}/element/image`,
        method: "POST",
        data: formData,
    });
}

export function addVideoExecutionElement(campaignExecutionId, name, file) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("video", file);

    return instance.request({
        url: `/cms/marketing/campaign/campaign-execution/${campaignExecutionId}/element/video`,
        method: "POST",
        data: formData,
    });
}

export function deleteExecutionElement(elementType, executionElementId) {
    return instance.request({
        url: `/cms/marketing/campaign/execution-element/${elementType}/${executionElementId}`,
        method: "DELETE",
    });
}
