import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";

const strings = {
    reports: "Reports",
    guests: "Guests",
    orders: "Orders",
    feedback: "Feedback",
    customers: "Customer Accounts",
    ccpa: "CCPA",
    landingPages: "Landing Pages",
    registrationPrompts: "Registration Prompts",
    contests: "Contests",
    nros: "NROs",
    campaignBuilder: "Campaign Builder",
    campaignPages: "Campaigns",
    campaignLocations: "Locations",
};

export default class Authenticated extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
    };

    _onSelect = v => {
        switch (v) {
            case 1.1:
                this.props.history.push("/stores");
                return;
            case 1.2:
                this.props.history.push("/renumber-stores");
                return;
            case 2.1:
                this.props.history.push("/categories");
                return;
            case 2.2:
                this.props.history.push("/combos");
                return;
            case 2.3:
                this.props.history.push("/items");
                return;
            case 2.4:
                this.props.history.push("/modifiers");
                return;
            case 2.5:
                this.props.history.push("/featured");
                return;
            case 3:
                this.props.history.push("/home-tiles");
                return;
            case 4.1:
                this.props.history.push("/orders");
                return;
            case 4.2:
                this.props.history.push("/guests");
                return;
            case 4.3:
                this.props.history.push("/feedback");
                return;
            case 4.4:
                this.props.history.push("/customers");
                return;
            case 5:
                this.props.history.push("/ccpa");
                return;
            case 6.1:
                this.props.history.push("/contests");
                return;
            case 6.2:
                this.props.history.push("/nros");
                return;
            case 6.3:
                this.props.history.push("/registration-prompts");
                return;
            case 7.1:
                this.props.history.push("/campaigns");
                return;
            case 7.2:
                this.props.history.push("/assign-campaigns");
                return;
            default:
        }
    };

    render() {
        return (
            <div>
                <header>
                    <Navbar inverse>
                        <Nav>
                            <NavDropdown eventKey={1} title="Stores" id="basic-nav-dropdown">
                                <MenuItem onSelect={this._onSelect} eventKey={1.1}>
                                    Stores
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={1.2}>
                                    Renumber Stores
                                </MenuItem>
                            </NavDropdown>
                            <NavDropdown eventKey={2} title="Menu" id="basic-nav-dropdown">
                                <MenuItem onSelect={this._onSelect} eventKey={2.1}>
                                    Categories
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={2.2}>
                                    Combos
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={2.3}>
                                    Items
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={2.4}>
                                    Modifiers
                                </MenuItem>
                                <MenuItem divider />
                                <MenuItem onSelect={this._onSelect} eventKey={2.5}>
                                    Featured Items
                                </MenuItem>
                            </NavDropdown>
                            <NavItem onSelect={this._onSelect} eventKey={3} href="#">
                                Home Tiles
                            </NavItem>
                            <NavDropdown
                                eventKey={5}
                                title={strings.reports}
                                id="basic-nav-dropdown"
                            >
                                <MenuItem onSelect={this._onSelect} eventKey={4.1}>
                                    {strings.orders}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={4.2}>
                                    {strings.guests}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={4.3}>
                                    {strings.feedback}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={4.4}>
                                    {strings.customers}
                                </MenuItem>
                            </NavDropdown>
                            <NavItem eventKey={5} onSelect={this._onSelect} href="#">
                                {strings.ccpa}
                            </NavItem>
                            <NavDropdown
                                eventKey={6}
                                title={strings.landingPages}
                                id="basic-nav-dropdown"
                            >
                                <MenuItem onSelect={this._onSelect} eventKey={6.1}>
                                    {strings.contests}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={6.2}>
                                    {strings.nros}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={6.3}>
                                    {strings.registrationPrompts}
                                </MenuItem>
                            </NavDropdown>
                            <NavDropdown
                                eventKey={7}
                                title={strings.campaignBuilder}
                                id="basic-nav-dropdown"
                            >
                                <MenuItem onSelect={this._onSelect} eventKey={7.1}>
                                    {strings.campaignPages}
                                </MenuItem>
                                <MenuItem onSelect={this._onSelect} eventKey={7.2}>
                                    {strings.campaignLocations}
                                </MenuItem>
                            </NavDropdown>
                        </Nav>
                    </Navbar>
                </header>
                {this.props.children}
            </div>
        );
    }
}
