/* eslint-disable react/jsx-no-bind */

import * as color from "../styles/color";
import ConfirmationModal from "./ConfirmationModal";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import StyledDropdown from "./StyledDropdown";
import XIcon from "./assets/x-icon.svg";
import { Checkbox, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import CampaignBadge from "./CampaignBadge";

const styles = StyleSheet.create({
    title: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: 0,
        marginBottom: "16px",
    },
    filteringContainer: {
        display: "flex",
        gap: "16px",
        marginBottom: "16px",
    },
    filterTextContainer: {
        flex: 6,
        marginBottom: 0,
    },
    filterStateContainer: {
        flex: 5,
        marginBottom: 0,
    },
    defaultOnlyContainer: {
        marginTop: "21px",
        marginBottom: 0,
    },
    defaultOnlyCheckbox: {
        marginTop: 0,
        marginBottom: 0,
    },
    storesContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
    },
    storeItem: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        backgroundColor: color.lightGrey,
        borderRadius: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "4px",
    },
    storeLabel: {
        flex: 1,
        fontSize: "16px",
        lineHeight: "20px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    closeButton: {
        border: "initial",
        backgroundColor: "initial",
        paddingLeft: "12px",
        paddingRight: "12px",
    },
});

const strings = {
    title: "Locations",
    filterTextLabel: "Search",
    filterTextPlaceholder: "Search stores",
    filterStateLabel: "State",
    filterStatePlaceholder: "Browse by State",
    defaultOnly: "Default Only",
    store: "Store",
    default: "Default",
    confirmRemove: "Remove",
    error: "ERROR",
    campaignNotAssigned: "This campaign is not currently assigned to any locations.",
};

export default class LocationListSection extends PureComponent {
    static propTypes = {
        stores: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                storeNumber: PropTypes.number.isRequired,
                isDefaultCampaign: PropTypes.bool.isRequired,
                state: PropTypes.string.isRequired,
            }),
        ).isRequired,
        onUnassignStore: PropTypes.func.isRequired,
    };

    state = {
        textFilter: "", // used to filter by store name, store number
        storeStateFilter: "", // used to filter by store states
        showDefaultOnly: false,
        showConfirmModal: false,
        removingStore: null,
    };

    _compareStore = (a, b) => {
        // If both stores are default or non-default, sort by store number
        if (
            (a.isDefaultCampaign && b.isDefaultCampaign) ||
            (!a.isDefaultCampaign && !b.isDefaultCampaign)
        ) {
            return a.storeNumber - b.storeNumber;
        }

        if (a.isDefaultCampaign) {
            return -1;
        }

        return 1;
    };

    _handleChangeTextFilter = e => {
        this.setState({ textFilter: e.target.value });
    };

    _handleChangeStoreStateFilter = state => {
        this.setState({ storeStateFilter: state === strings.filterStatePlaceholder ? "" : state });
    };

    _handleChangeShowDefaultOnly = () => {
        this.setState(prevState => ({ showDefaultOnly: !prevState.showDefaultOnly }));
    };

    _handleConfirmModalCancel = () => {
        this.setState({ showConfirmModal: false, removingStore: null });
    };

    _handleConfirmModalConfirm = () => {
        this.setState({ showConfirmModal: false, removingStore: null });
        if (!this.state.removingStore) {
            return;
        }
        this.props.onUnassignStore(
            this.state.removingStore.id,
            this.state.removingStore.isDefaultCampaign,
        );
    };

    render() {
        const loweredFilterText = this.state.textFilter.toLowerCase().trim();

        const stateFilterOptions = [{ value: strings.filterStatePlaceholder }];
        [...new Set(this.props.stores.map(store => store.state))].sort().forEach(state => {
            stateFilterOptions.push({ value: state });
        });

        const filteredStores = this.props.stores.filter(store => {
            if (this.state.showDefaultOnly && !store.isDefaultCampaign) {
                return false;
            }

            if (this.state.storeStateFilter && store.state !== this.state.storeStateFilter) {
                return false;
            }

            return (
                store.name.toLowerCase().indexOf(loweredFilterText) !== -1 ||
                store.storeNumber.toString().indexOf(loweredFilterText) !== -1
            );
        });
        filteredStores.sort(this._compareStore);

        return (
            <>
                <div className={css(styles.container)}>
                    <h2 className={css(styles.title)}>{strings.title}</h2>
                    {this.props.stores.length > 0 ? (
                        <>
                            <div className={css(styles.filteringContainer)}>
                                <FormGroup
                                    controlId="textFilter"
                                    className={css(styles.filterTextContainer)}
                                >
                                    <ControlLabel>{strings.filterTextLabel}</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.textFilter}
                                        placeholder={strings.filterTextPlaceholder}
                                        onChange={this._handleChangeTextFilter}
                                    />
                                </FormGroup>
                                <FormGroup
                                    controlId="storeStateFilter"
                                    className={css(styles.filterStateContainer)}
                                >
                                    <ControlLabel>{strings.filterStateLabel}</ControlLabel>
                                    <StyledDropdown
                                        id="storeStateFilter"
                                        placeholder={strings.filterStatePlaceholder}
                                        value={this.state.storeStateFilter}
                                        onChange={this._handleChangeStoreStateFilter}
                                        options={stateFilterOptions}
                                    />
                                </FormGroup>
                                <FormGroup className={css(styles.defaultOnlyContainer)}>
                                    <Checkbox
                                        className={css(styles.defaultOnlyCheckbox)}
                                        checked={this.state.showDefaultOnly}
                                        onChange={this._handleChangeShowDefaultOnly}
                                    >
                                        {strings.defaultOnly}
                                    </Checkbox>
                                </FormGroup>
                            </div>
                            <div className={css(styles.storesContainer)}>
                                {filteredStores.map(store => (
                                    <div key={store.id} className={css(styles.storeItem)}>
                                        <span className={css(styles.storeLabel)}>
                                            {`${strings.store} ${
                                                store.storeNumber
                                            } - ${store.name.toUpperCase()}`}
                                        </span>
                                        {store.isDefaultCampaign ? (
                                            <CampaignBadge>{strings.default}</CampaignBadge>
                                        ) : null}
                                        <button
                                            className={css(styles.closeButton)}
                                            onClick={() => {
                                                this.setState({
                                                    showConfirmModal: true,
                                                    removingStore: store,
                                                });
                                            }}
                                        >
                                            <img
                                                src={XIcon}
                                                alt={`Remove location number ${store.storeNumber} from this campaign`}
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div>{strings.campaignNotAssigned}</div>
                    )}
                </div>
                <ConfirmationModal
                    show={this.state.showConfirmModal}
                    prompt={`Are you sure you want to remove location number ${
                        this.state.removingStore
                            ? this.state.removingStore.storeNumber
                            : strings.error
                    } from this campaign?`}
                    confirmButtonLabel={strings.confirmRemove}
                    handleCancel={this._handleConfirmModalCancel}
                    handleConfirm={this._handleConfirmModalConfirm}
                />
            </>
        );
    }
}
