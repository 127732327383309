import AssignCampaignsPage from "./pages/AssignCampaignsPage";
import Authenticated from "./templates/Authenticated";
import CampaignPage from "./pages/CampaignPage";
import CampaignsPage from "./pages/CampaignsPage";
import CategoriesPage from "./pages/CategoriesPage";
import CategoryPage from "./pages/CategoryPage";
import CCPAPage from "./pages/CCPAPage";
import ComboPage from "./pages/ComboPage";
import CombosPage from "./pages/CombosPage";
import ContestPage from "./pages/ContestPage";
import ContestsPage from "./pages/ContestsPage";
import CustomerAccountsPage from "./pages/CustomerAccountsPage";
import FeaturedProductPage from "./pages/FeaturedProductPage";
import FeaturedProductsPage from "./pages/FeaturedProductsPage";
import FeedbackPage from "./pages/FeedbackPage";
import GuestOrdersPage from "./pages/GuestOrdersPage";
import GuestsPage from "./pages/GuestsPage";
import ItemModifierPage from "./pages/ItemModifierPage";
import ItemPage from "./pages/ItemPage";
import ItemsPage from "./pages/ItemsPage";
import LoginPage from "./pages/LoginPage";
import ModifierPage from "./pages/ModifierPage";
import ModifiersPage from "./pages/ModifiersPage";
import NotificationPage from "./pages/NotificationPage";
import NROPage from "./pages/NROPage";
import NROsPage from "./pages/NROsPage";
import OrdersPage from "./pages/OrdersPage";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import RegistrationPromptPage from "./pages/RegistrationPromptPage";
import RegistrationPromptsPage from "./pages/RegistrationPromptsPage";
import RenumberStoresPage from "./pages/RenumberStoresPage";
import StoreImportPage from "./pages/StoreImportPage";
import StorePage from "./pages/StorePage";
import StoresPage from "./pages/StoresPage";
import TilesPage from "./pages/TilesPage";
import Unauthenticated from "./templates/Unauthenticated";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

class Router extends PureComponent {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
    };

    _renderUnauthenticated = () => {
        if (this.props.isAuthenticated) {
            return <Redirect to="/hospitals" />;
        }
        return (
            <Unauthenticated>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                </Switch>
            </Unauthenticated>
        );
    };

    _renderAuthenticated = props => {
        if (this.props.isAuthenticated) {
            return (
                <Authenticated {...props}>
                    <Switch>
                        <Route path="/categories" exact component={CategoriesPage} />
                        <Route path="/category/:categoryId" component={CategoryPage} />
                        <Route path="/items" exact component={ItemsPage} />
                        <Route
                            path="/item/:itemId/modifier/:modifierId"
                            component={ItemModifierPage}
                        />
                        <Route path="/item/:itemId" component={ItemPage} />
                        <Route path="/combos" exact component={CombosPage} />
                        <Route path="/combo/:comboId" component={ComboPage} />
                        <Route path="/renumber-stores" component={RenumberStoresPage} />
                        <Route path="/stores" exact component={StoresPage} />
                        <Route path="/store/:storeId" component={StorePage} />
                        <Route path="/modifiers" exact component={ModifiersPage} />
                        <Route path="/modifier/:modifierId" component={ModifierPage} />
                        <Route path="/featured" exact component={FeaturedProductsPage} />
                        <Route path="/home-tiles" exact component={TilesPage} />
                        <Route path="/orders" exact component={OrdersPage} />
                        <Route path="/orders/:accountId" component={GuestOrdersPage} />
                        <Route path="/guests" exact component={GuestsPage} />
                        <Route path="/customers" exact component={CustomerAccountsPage} />
                        <Route path="/menu-import" exact component={StoreImportPage} />
                        <Route path="/feedback" exact component={FeedbackPage} />
                        <Route path="/notification" exact component={NotificationPage} />
                        <Route path="/ccpa" exact component={CCPAPage} />
                        <Route
                            path="/featured/:featuredProductId"
                            component={FeaturedProductPage}
                        />
                        <Route
                            path="/registration-prompts"
                            exact
                            component={RegistrationPromptsPage}
                        />
                        <Route
                            path="/registration-prompt/:registrationPromptId"
                            // eslint-disable-next-line react/jsx-no-bind
                            component={() => new RegistrationPromptPage()}
                        />
                        <Route path="/contests" exact component={ContestsPage} />
                        <Route
                            path="/contest/:contestId"
                            // eslint-disable-next-line react/jsx-no-bind
                            component={() => new ContestPage()}
                        />
                        <Route path="/nros" exact component={NROsPage} />
                        <Route
                            path="/nro/:nroId"
                            // eslint-disable-next-line react/jsx-no-bind
                            component={() => new NROPage()}
                        />
                        <Route path="/campaigns" exact component={CampaignsPage} />
                        <Route
                            path="/campaign/:campaignId"
                            // eslint-disable-next-line react/jsx-no-bind
                            component={() => new CampaignPage()}
                        />
                        <Route path="/assign-campaigns" exact component={AssignCampaignsPage} />
                        <Redirect from="" to="/stores" />
                    </Switch>
                </Authenticated>
            );
        }
        return <Redirect to="/login" />;
    };

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" render={this._renderUnauthenticated} />
                    <Route path="/" render={this._renderAuthenticated} />
                </Switch>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.session.accessToken,
    };
};

export default connect(mapStateToProps)(Router);
