/* eslint-disable react/jsx-no-bind */

import * as color from "../styles/color";
import * as executionElementType from "../enums/executionElementType";
import CampaignExecutionElement from "./CampaignExecutionElement";
import CampaignExecutionNewElement from "./CampaignExecutionNewElement";
import executionElementDefaultName from "../enums/executionElementDefaultName";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import { DropdownButton, MenuItem } from "react-bootstrap";

const styles = StyleSheet.create({
    container: {
        marginBottom: "24px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: color.lightGrey,
        borderRadius: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "12px",
        paddingRight: "12px",
        marginBottom: "24px",
        fontSize: "16px",
        fontWeight: 600,
    },
    elementsContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        gap: "16px",
        flexWrap: "wrap",
        width: "100%",
    },
    loadingContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: color.whiteRgb3,
    },
});

const strings = {
    newElementLabel: "Add Element",
    defaultImageName: "New Image",
    defaultVideoName: "New Video",
};

class CampaignExecution extends PureComponent {
    static propTypes = {
        platform: PropTypes.string.isRequired,
        execution: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            requirements: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
                video: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            elements: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                    data: PropTypes.object.isRequired,
                }),
            ).isRequired,
            newElements: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string.isRequired,
                    data: PropTypes.object.isRequired,
                }),
            ).isRequired,
            replaceElements: PropTypes.object.isRequired,
            deletedElements: PropTypes.object.isRequired,
        }),
        addNewElement: PropTypes.func.isRequired,
        editNewElement: PropTypes.func.isRequired,
        removeNewElement: PropTypes.func.isRequired,
        addReplaceElement: PropTypes.func.isRequired,
        editReplaceElement: PropTypes.func.isRequired,
        removeReplaceElement: PropTypes.func.isRequired,
        addDeletedElement: PropTypes.func.isRequired,
        removeDeletedElement: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    _formatElementType = typeName => {
        if (typeName.length === 0) {
            return "";
        }

        const firstLetter = typeName.charAt(0);
        return `${firstLetter.toUpperCase()}${typeName.slice(1).toLowerCase()}`;
    };

    _onSelect = type => {
        this.props.addNewElement(this.props.platform, this.props.execution.id, {
            type,
            data: {
                name: executionElementDefaultName[type],
            },
        });
    };

    _handleUpdateNewElement = (i, element) => {
        this.props.editNewElement(this.props.platform, this.props.execution.id, i, element);
    };

    _handleRemoveNewElement = i => {
        this.props.removeNewElement(this.props.platform, this.props.execution.id, i);
    };

    _handleReplaceElement = id => {
        this.props.addReplaceElement(this.props.platform, this.props.execution.id, id);
    };

    _handleUpdateReplaceElement = replaceElement => {
        this.props.editReplaceElement(this.props.platform, this.props.execution.id, replaceElement);
    };

    _handleCancelReplaceElement = id => {
        this.props.removeReplaceElement(this.props.platform, this.props.execution.id, id);
    };

    _handleAddDeletedElement = element => {
        this.props.addDeletedElement(this.props.platform, this.props.execution.id, element);
    };

    _handleRemoveDeletedElement = id => {
        this.props.removeDeletedElement(this.props.platform, this.props.execution.id, id);
    };

    render() {
        return (
            <>
                <div className={css(styles.container)}>
                    <div className={css(styles.header)}>
                        <span>{this.props.execution.name}</span>
                        <DropdownButton
                            id="add-execution-element-dropdown"
                            title={strings.newElementLabel}
                            disabled={this.props.disabled}
                        >
                            <MenuItem
                                eventKey={executionElementType.image}
                                onSelect={this._onSelect}
                            >
                                {this._formatElementType(executionElementType.image)}
                            </MenuItem>
                            <MenuItem
                                eventKey={executionElementType.video}
                                onSelect={this._onSelect}
                            >
                                {this._formatElementType(executionElementType.video)}
                            </MenuItem>
                        </DropdownButton>
                    </div>
                    <div className={css(styles.elementsContainer)}>
                        {this.props.execution.elements.map(element => (
                            <CampaignExecutionElement
                                key={element.id}
                                element={element}
                                requirements={this.props.execution.requirements}
                                disabled={this.props.disabled}
                                replaceElement={this.props.execution.replaceElements[element.id]}
                                onReplace={this._handleReplaceElement}
                                onUpdateReplace={this._handleUpdateReplaceElement}
                                onCancelReplace={this._handleCancelReplaceElement}
                                isDeleted={!!this.props.execution.deletedElements[element.id]}
                                onDelete={this._handleAddDeletedElement}
                                onCancelDelete={this._handleRemoveDeletedElement}
                            />
                        ))}
                        {this.props.execution.newElements.map((element, i) => (
                            <CampaignExecutionNewElement
                                key={i}
                                index={i}
                                element={element}
                                requirements={this.props.execution.requirements}
                                disabled={this.props.disabled}
                                onUpdateData={this._handleUpdateNewElement}
                                onRemove={this._handleRemoveNewElement}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default CampaignExecution;
