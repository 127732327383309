import { StyleSheet } from "aphrodite";
import * as color from "./color";

export default StyleSheet.create({
    imagePreview: {
        textAlign: "center",
        width: 90,
    },
    danger: {
        color: color.orange,
    },
});
