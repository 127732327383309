import * as color from "../styles/color";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    video: {
        width: "100%",
        maxHeight: "200px",
        objectFit: "contain",
        borderRadius: "4px",
        marginBottom: "12px",
        backgroundColor: color.black,
    },
});

const strings = {
    title: "Video",
    uploadFileLabel: "Upload Video",
    permanentRequirements: "Must be .mp4",
    requirementsDivider: "|",
    unsetRequirement: "UNSET",
    save: "Save",
    noFileSelectedAlertMsg: "Please select a video and try again.",
};

class VideoUploader extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        hideTitle: PropTypes.bool,
        requirements: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        onFileUpdate: PropTypes.func.isRequired,
    };

    state = {
        fileUrl: "",
        showAlertModal: false,
    };

    componentWillUnmount() {
        if (this.state.fileUrl) {
            URL.revokeObjectURL(this.state.fileUrl);
        }
    }

    _handleChangeFile = e => {
        if (this.state.fileUrl) {
            URL.revokeObjectURL(this.state.fileUrl);
        }
        const file = e.target.files[0];
        this.props.onFileUpdate(file);
        this.setState({ fileUrl: file ? URL.createObjectURL(file) : "" });
    };

    _getRequirementsText = () => {
        const widthRequirement =
            this.props.requirements && this.props.requirements.width
                ? `${this.props.requirements.width}px`
                : "";
        const heightRequirement =
            this.props.requirements && this.props.requirements.height
                ? `${this.props.requirements.height}px`
                : "";
        const dimensionRequirements =
            widthRequirement || heightRequirement
                ? ` ${strings.requirementsDivider} Dimensions: ${
                      widthRequirement ? widthRequirement : strings.unsetRequirement
                  } by ${heightRequirement ? heightRequirement : strings.unsetRequirement}`
                : "";
        return `${strings.permanentRequirements}${dimensionRequirements}`;
    };

    render() {
        return (
            <>
                <div className={css(this.props.className)}>
                    {!this.props.hideTitle ? (
                        <h4>{this.props.title ? this.props.title : strings.title}</h4>
                    ) : null}
                    {this.state.fileUrl ? (
                        <video
                            className={css(styles.video)}
                            src={this.state.fileUrl}
                            controls
                            preload="none"
                        />
                    ) : null}
                    <FormGroup controlId="videoUpload">
                        <ControlLabel>{strings.uploadFileLabel}</ControlLabel>
                        <FormControl type="file" accept=".mp4" onChange={this._handleChangeFile} />
                        <HelpBlock>{this._getRequirementsText()}</HelpBlock>
                    </FormGroup>
                </div>
            </>
        );
    }
}

export default VideoUploader;
